<template>
  <!-- sticky-top -->
  <header v-navScroll="{ height: '20' }" class="header sticky-top">
    <nav
      class="navbar navbar-expand-lg navbar-light py-3 py-lg-0"
      style="background: rgba(255, 255, 255, 0.95)"
    >
      <div class="container-fluid">
        <div class="px-4">
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            aria-controls="navbarScroll"
            aria-expanded="false"
            aria-label="Toggle navigation"
            @click="navbarToggle = !navbarToggle"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
        </div>
        <!-- LOGO  -->
        <div class="navbar-brand me-0 me-lg-5 cursor-pointer" @click="$router.push('/')">
          <img
            :src="$imageFilters('/images/Logo_02.svg')"
            alt="web-logo"
            class="navbar-brand-item"
            height="35"
          />
        </div>
        <!-- LAPTOP NAV START -->
        <div class="collapse navbar-collapse">
          <ul class="navbar-nav navbar-nav-scroll">
            <li
              v-dropdown="{ id: `dropdown-menu-${idx}` }"
              class="nav-item ms-2 dropdown"
              v-for="(nav, idx) in navItems"
              :key="`nav${idx}`"
            >
              <ul>
                <li>
                  <router-link
                    v-if="nav.url"
                    :to="nav.url"
                    class="nav-link dropdown-toggle"
                    role="button"
                    aria-expanded="false"
                    >{{ nav.name }}</router-link
                  >
                  <p v-else class="nav-link cursor-pointer">
                    {{ nav.name }}
                  </p>
                </li>
              </ul>
              <ul>
                <li>
                  <keep-alive>
                    <component
                      :is="componentName(nav.type.toLowerCase())"
                      :nav="nav.children"
                      :id="idx"
                      :type="nav.type"
                    />
                  </keep-alive>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <!-- laptop NAV END -->
      </div>
      <!-- MOBILE NAV START -->
      <div class="mobile-collapse d-lg-none" :class="{ show: navbarToggle }">
        <ul class="navbar-nav navbar-nav-scroll py-3 ps-7">
          <li
            class="nav-item me-3 mb-3"
            v-for="(nav, idx) in navItems"
            :key="`mobile${nav.title}${idx}`"
          >
            <a
              href="#"
              class="fs-6"
              @click.prevent="
                navbarToggle = !navbarToggle;
                $router.push(nav.url);
              "
              >{{ nav.name }}</a
            >
          </li>
        </ul>
      </div>
      <!-- MOBILE NAV END -->
    </nav>
  </header>
</template>

<script>
import NavSolutionStyle from "../components/NavSolutionStyle.vue";
import NavRegularStyle from "../components/NavRegularStyle.vue";
import NavProductStyle from "../components/NavProductStyle.vue";

export default {
  name: "Header",
  components: {
    NavSolutionStyle,
    NavRegularStyle,
    NavProductStyle,
  },
  props: {
    navItems: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      navbarToggle: false,
    };
  },
  computed: {
    componentName() {
      return (type) =>
        type === "solution"
          ? "NavSolutionStyle"
          : type === "product"
          ? "NavProductStyle"
          : "NavRegularStyle";
    },
  },
};
</script>
