<template>
  <div>
    <Header :navItems="menuList" />
    <router-view :key="$route" />
    <Footer :navItems="menuList" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Header from "./Header.vue";
import Footer from "./Footer.vue";
export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      navItems: [],
    };
  },
  computed: {
    ...mapGetters("menu", ["menuList"]),
  },
};
</script>
