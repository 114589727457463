<template>
  <div
    class="dropdown-menu bg-white position-fixed menu-solution-bg border"
    style="left: 13%"
    :id="`dropdown-menu-${id}`"
  >
    <div class="container-fluid">
      <ul class="d-flex justify-content-between py-4">
        <li v-for="(item, idx) in nav" :key="`navItem${idx}`" class="px-5">
          <p class="mb-2">
            <a
              class="dropdown-item text-primary fs-7 fw-bold"
              href="#"
              @click.prevent="redirectURL(item.url)"
              >{{ item.name }}</a
            >
          </p>
          <a
            v-for="d in item.children"
            :key="d"
            class="dropdown-item text-info fs-7 d-flex align-items-center"
            href="#"
            @click.prevent="redirectURL(d.url)"
          >
            <img
              :src="d.imagePath && $imageFilters(d.imagePath)"
              style="width: 20px"
              class="me-2"
            />
            {{ d.name }}
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "navSolutionStyle",
  props: {
    id: {
      type: [Number, String],
    },
    nav: {
      type: Object,
    },
  },
  methods: {
    redirectURL(uri) {
      if (!uri) return;
      if (uri.includes("https://")) {
        window.open(uri, "_blank");
      } else {
        this.$router.push(uri);
      }
    },
  },
};
</script>

<style scoped>
.menu-solution-bg {
  background-repeat: no-repeat;
  background-position: 100% 100%;
  background-size: 20%;
}
</style>
