<template>
  <footer>
    <div class="text-center fs-6 py-5 py-lg-8">
      <div class="d-flex justify-content-center mb-2">
        <img :src="$imageFilters('/images/Icon_01.svg')" style="width: 20px" class="me-3" />
        <h3 class="fs-6 fw-light" contenteditable="true">02-2298-2958</h3>
      </div>
      <h3 class="fs-6 mb-3 fw-light">免付費服務專線：0800-077-758</h3>
      <router-link :to="{ name: 'contact' }">
        <img
          :src="$imageFilters('/images/contact_01.svg')"
          style="width: 120px"
          class="text-center mx-auto"
        />
      </router-link>
    </div>
    <div class="py-lg-6 d-none d-lg-block bg-primary">
      <nav class="container">
        <ul class="d-lg-flex justify-content-between w-100">
          <li class="me-4" v-for="nav in navItems" :key="nav.id">
            <a
              :href="nav.url"
              class="mb-2 d-block text-white border-bottom border-white border-1"
              >{{ nav.name }}</a
            >
            <ul style="height: 210px" class="overflow-hidden">
              <li
                v-for="content in children(nav.type, nav.children)"
                :key="content.id"
                class="mb-1"
              >
                <div v-if="content.url">
                  <a
                    v-if="content.url.includes('https://')"
                    :href="content.url"
                    target="_blank"
                    class="text-white fw-lighter fs-7"
                    >{{ content.name }}</a
                  >
                  <router-link v-else :to="content.url" class="text-white fw-lighter fs-7">{{
                    content.name
                  }}</router-link>
                </div>
                <p v-else class="text-white fw-lighter fs-7">{{ content.name }}</p>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    </div>

    <p class="bg-black text-center text-white py-3 fs-8">
      © {{ new Date().getFullYear() }}仁大資訊股份有限公司
    </p>
  </footer>
</template>

<script>
import { SOLUTION } from "@/constants/article";

export default {
  props: {
    navItems: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    children() {
      return (type, arr) => (type === SOLUTION.key ? arr : arr[0].children);
    },
  },
};
</script>
