<template>
  <div
    class="dropdown-menu bg-white position-fixed border py-0 translatex-middle-x w-55"
    :id="`dropdown-menu-${id}`"
    style="left: 13%"
  >
    <div class="container-fluid">
      <ul class="py-5 px-8 row flex-column" style="height: 220px">
        <li v-for="(c, idx) in productItem" :key="`navItem${idx}`" class="px-5 col-3">
          <a
            class="dropdown-item text-info align-items-center fs-7 d-flex"
            href="#"
            @click.prevent="redirectURL(c.url)"
          >
            <img
              :src="c.imagePath && $imageFilters(c.imagePath)"
              style="width: 20px"
              class="me-2"
            />
            {{ c.name }}
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "navProductStyle",
  props: {
    id: {
      type: [Number, String],
    },
    nav: {
      type: Object,
    },
    type: {
      type: String,
    },
  },
  computed: {
    productItem() {
      return this.nav && this.nav[0].children.slice(0, 16);
    },
  },
  methods: {
    redirectURL(uri) {
      if (!uri) return;
      if (uri.includes("https://")) {
        window.open(uri, "_blank");
      } else {
        this.$router.push(uri);
      }
    },
  },
};
</script>
